export const storage = {
    get: async (key) => {
      return new Promise((resolve) => {
        if (chrome.storage) {
          chrome.storage.local.get([key], (result) => {
            resolve(result[key]);
          });
        } else {
          // Fallback to localStorage for development
          const value = localStorage.getItem(key);
          resolve(value ? JSON.parse(value) : null);
        }
      });
    },
    
    set: async (key, value) => {
      return new Promise((resolve) => {
        if (chrome.storage) {
          chrome.storage.local.set({ [key]: value }, () => {
            resolve();
          });
        } else {
          // Fallback to localStorage for development
          localStorage.setItem(key, JSON.stringify(value));
          resolve();
        }
      });
    }
  };