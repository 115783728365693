import React, { useState, useEffect, useCallback } from 'react';
import { initializeFirebase } from './utils/initFirebase';
import TaskDashboard from './components/TaskDashboard';
import ProgressCard from './components/ProgressCard';
import MemeCard from './components/MemeCard';
import ThemeSwitcher from './components/ThemeSwitcher';
import UserSetup from './components/UserSetup';
import Login from './components/Login';
import Footer from './components/Footer';
import { useAuth } from './contexts/AuthContext';
import { dbService } from './services/dbService';
import { taskService } from './utils/taskService';
import { themes } from './utils/themeUtils';
import { getTimeOfDay } from './utils/timeUtils';
import { Button } from '@/components/ui/button';
import { Settings, LogOut } from 'lucide-react';

const LOADING_STATE = {
  INITIALIZING: 'initializing',
  LOADING: 'loading',
  READY: 'ready',
  ERROR: 'error'
};

const App = () => {
  const { user, signOut } = useAuth();
  
  // Core state
  const [tasks, setTasks] = useState([]);
  const [theme, setTheme] = useState('dark');
  const [weeklyGoal, setWeeklyGoal] = useState(144000);
  const [totalTime, setTotalTime] = useState(0);
  
  // UI state
  const [showUserSetup, setShowUserSetup] = useState(false); // Changed to false initially
  const [showSettings, setShowSettings] = useState(false);
  const [userName, setUserName] = useState('User');
  const [loadingState, setLoadingState] = useState(LOADING_STATE.INITIALIZING);
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState(null);

  // Initialize Firebase first
  useEffect(() => {
    const init = async () => {
      try {
        setLoadingState(LOADING_STATE.INITIALIZING);
        const initialized = await initializeFirebase();
        if (!initialized) {
          console.error('Failed to initialize Firebase');
          setLoadingState(LOADING_STATE.ERROR);
          setError('Failed to connect to the service. Please check your internet connection and try again.');
          return;
        }
        setIsInitialized(true);
        setLoadingState(LOADING_STATE.READY);
      } catch (error) {
        console.error('Firebase initialization error:', error);
        setLoadingState(LOADING_STATE.ERROR);
        setError('Failed to initialize application. Please refresh and try again.');
      }
    };

    init();
  }, []);

  // Load user data after Firebase is initialized
  useEffect(() => {
    const loadUserData = async () => {
      if (!isInitialized || !user) return;

      try {
        setLoadingState(LOADING_STATE.LOADING);
        
        const userData = await dbService.getUserData(user.uid);
        
        if (userData) {
          setUserName(userData.displayName || user.displayName);
          setWeeklyGoal(userData.weeklyGoal || 144000);
          setTheme(userData.theme || 'dark');
          
          if (userData.tasks) {
            const updatedTasks = await taskService.updateRunningTasks(userData.tasks);
            setTasks(updatedTasks);
          }
        } else {
          setShowUserSetup(true);
          await dbService.saveUserData(user.uid, {
            displayName: user.displayName,
            email: user.email,
            weeklyGoal: 144000,
            theme: 'dark',
            tasks: []
          });
        }

        setLoadingState(LOADING_STATE.READY);
      } catch (error) {
        console.error('Error loading user data:', error);
        setError('Failed to load user data. Please try again.');
        setLoadingState(LOADING_STATE.ERROR);
      }
    };

    loadUserData();
  }, [user, isInitialized]);

  // Task Management Handlers
  const addTask = useCallback(async (newTask) => {
    if (!user || !isInitialized) return;

    try {
      const taskToAdd = {
        ...newTask,
        id: Date.now().toString(),
        time: 0,
        lastUpdated: Date.now(),
        isCompleted: false,
        isRunning: false
      };

      const updatedTasks = [...tasks, taskToAdd];
      await dbService.saveTasks(user.uid, updatedTasks);
      setTasks(updatedTasks);
    } catch (error) {
      console.error('Error adding task:', error);
      setError('Failed to add task. Please try again.');
    }
  }, [tasks, user, isInitialized]);

  const updateTask = useCallback(async (updatedTask) => {
    if (!user || !isInitialized) return;

    try {
      const updatedTasks = tasks.map(task =>
        task.id === updatedTask.id ? { ...updatedTask, lastUpdated: Date.now() } : task
      );
      await dbService.saveTasks(user.uid, updatedTasks);
      setTasks(updatedTasks);
    } catch (error) {
      console.error('Error updating task:', error);
      setError('Failed to update task. Please try again.');
    }
  }, [tasks, user, isInitialized]);

  const deleteTask = useCallback(async (taskId) => {
    if (!user || !isInitialized) return;

    try {
      const updatedTasks = tasks.filter(task => task.id !== taskId);
      await dbService.saveTasks(user.uid, updatedTasks);
      setTasks(updatedTasks);
    } catch (error) {
      console.error('Error deleting task:', error);
      setError('Failed to delete task. Please try again.');
    }
  }, [tasks, user, isInitialized]);

  // Settings Handlers
  const handleUserSetup = useCallback(async (userData) => {
    if (!user || !isInitialized) return;

    try {
      await dbService.updateUserSettings(user.uid, {
        displayName: userData.userName,
        weeklyGoal: userData.weeklyGoal
      });

      setUserName(userData.userName);
      setWeeklyGoal(userData.weeklyGoal);
      setShowUserSetup(false);
    } catch (error) {
      console.error('Error updating user settings:', error);
      setError('Failed to save settings. Please try again.');
    }
  }, [user, isInitialized]);

  const handleSettingsUpdate = useCallback(async (userData) => {
    if (!user || !isInitialized) return;

    try {
      await dbService.updateUserSettings(user.uid, {
        weeklyGoal: userData.weeklyGoal
      });

      setWeeklyGoal(userData.weeklyGoal);
      setShowSettings(false);
    } catch (error) {
      console.error('Error updating settings:', error);
      setError('Failed to update settings. Please try again.');
    }
  }, [user, isInitialized]);

  const handleSignOut = async () => {
    try {
      await signOut();
      // Clear local state
      setTasks([]);
      setUserName('User');
      setWeeklyGoal(144000);
      setTheme('dark');
    } catch (error) {
      console.error('Error signing out:', error);
      setError('Failed to sign out. Please try again.');
    }
  };

  // Render states
  if (loadingState === LOADING_STATE.INITIALIZING || loadingState === LOADING_STATE.LOADING) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <div className="space-y-4 text-center">
          <div className="text-2xl font-bold text-white">TabTask</div>
          <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto"></div>
        </div>
      </div>
    );
  }

  if (loadingState === LOADING_STATE.ERROR) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <div className="space-y-4 text-center text-white">
          <div className="text-2xl font-bold">Oops!</div>
          <div>{error || 'Something went wrong. Please try again.'}</div>
          <Button 
            variant="outline" 
            onClick={() => window.location.reload()}
          >
            Retry
          </Button>
        </div>
      </div>
    );
  }

  if (!user) {
    return <Login />;
  }

  return (
    <>
      <UserSetup 
        open={showUserSetup} 
        onComplete={handleUserSetup}
        isInitialSetup={true}
        initialData={{ userName: user.displayName }}
      />
      
      <UserSetup 
        open={showSettings}
        onComplete={handleSettingsUpdate}
        isInitialSetup={false}
        initialData={{ userName, weeklyGoal }}
      />

      <div className={`min-h-screen ${themes[theme].background} ${themes[theme].text}`}>
        <div className="p-8">
          <div className="max-w-7xl mx-auto">
            <header className="flex justify-between items-center mb-8">
              <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                TabTask
              </h1>
              <div className="flex items-center space-x-4">
                <div className="text-right flex items-center gap-4">
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    onClick={() => setShowSettings(true)}
                    className="flex items-center gap-2"
                  >
                    <Settings className="h-4 w-4" />
                    Set Goal
                  </Button>
                  <p className="font-semibold">
                    Good {getTimeOfDay()}, {userName}
                  </p>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={handleSignOut}
                    className="flex items-center gap-2"
                  >
                    <LogOut className="h-4 w-4" />
                    Sign Out
                  </Button>
                </div>
                <ThemeSwitcher currentTheme={theme} onThemeChange={setTheme} />
              </div>
            </header>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              <div className="lg:col-span-2">
                <TaskDashboard
                  tasks={tasks}
                  onAddTask={addTask}
                  onUpdateTask={updateTask}
                  onDeleteTask={deleteTask}
                  theme={theme}
                />
              </div>
              
              <div className="space-y-8">
                <ProgressCard
                  tasks={tasks}
                  totalTime={totalTime}
                  weeklyGoal={weeklyGoal}
                  theme={theme}
                  onSetGoal={() => setShowSettings(true)}
                />
                <MemeCard theme={theme} />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default App;