import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import TaskItem from './TaskItem';
import TaskDialog from './TaskDialog';

const TaskDashboard = ({ tasks, onAddTask, onUpdateTask, onDeleteTask, theme }) => {
  const [activeTab, setActiveTab] = useState('all');
  const [sortBy, setSortBy] = useState('dueDate');
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState('');
  const [taskToEdit, setTaskToEdit] = useState(null);

  const filterTasks = (tasks) => {
    switch (activeTab) {
      case 'todo':
        return tasks.filter(task => !task.isCompleted && !task.isRunning);
      case 'inProgress':
        return tasks.filter(task => !task.isCompleted && task.isRunning);
      case 'completed':
        return tasks.filter(task => task.isCompleted);
      default:
        return tasks;
    }
  };

  const sortTasks = (tasks) => {
    return [...tasks].sort((a, b) => {
      if (sortBy === 'dueDate') {
        return new Date(a.dueDate) - new Date(b.dueDate);
      }
      return a.estimatedTime - b.estimatedTime;
    });
  };

  const handleQuickAdd = (e, isQuickAdd = false) => {
    e.preventDefault();
    if (newTaskTitle.trim()) {
      const newTask = {
        title: newTaskTitle,
        description: '',
        category: 'Work',
        estimatedTime: 3600,
        dueDate: new Date().toISOString().split('T')[0],
        isCompleted: false,
        isRunning: false
      };

      if (isQuickAdd) {
        // Command+Enter was pressed - add task directly
        onAddTask(newTask);
        setNewTaskTitle('');
      } else {
        // Normal add - show dialog with pre-filled data
        setTaskToEdit(newTask);
        setShowAddDialog(true);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
      handleQuickAdd(e, true);
    }
  };

  const handleTaskSave = (task) => {
    onAddTask(task);
    setShowAddDialog(false);
    setTaskToEdit(null);
    setNewTaskTitle('');
  };

  return (
    <Card className="border-none shadow-lg">
      <CardHeader>
        <CardTitle className="flex justify-between items-center">
          <span className="text-2xl font-bold">Tasks</span>
          <div className="flex space-x-2">
            <Select value={sortBy} onValueChange={setSortBy}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Sort by" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="dueDate">Due Date</SelectItem>
                <SelectItem value="estimatedTime">Estimated Time</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </CardTitle>
        <CardDescription>
          Manage your tasks and track your progress
        </CardDescription>
        <form onSubmit={(e) => handleQuickAdd(e, false)} className="flex mt-4 gap-2">
          <div className="flex-grow">
            <Input
              placeholder="Add a new task (Press Cmd/Ctrl + Enter for quick add)"
              value={newTaskTitle}
              onChange={(e) => setNewTaskTitle(e.target.value)}
              onKeyDown={handleKeyDown}
              className="w-full"
            />
          </div>
          <Button 
            type="submit" 
            className="bg-blue-600 hover:bg-blue-700 min-w-[130px] px-4 flex items-center justify-center"
          >
            <Plus className="h-4 w-4 mr-2" /> 
            <span>Add Task</span>
          </Button>
        </form>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="all" className="w-full" onValueChange={setActiveTab}>
          <TabsList className="grid w-full grid-cols-4 mb-4">
            <TabsTrigger value="all">All</TabsTrigger>
            <TabsTrigger value="todo">To Do</TabsTrigger>
            <TabsTrigger value="inProgress">In Progress</TabsTrigger>
            <TabsTrigger value="completed">Completed</TabsTrigger>
          </TabsList>
          <TabsContent value={activeTab}>
            <ScrollArea className="h-[500px] w-full pr-4">
              {sortTasks(filterTasks(tasks)).map(task => (
                <TaskItem
                  key={task.id}
                  task={task}
                  onUpdate={onUpdateTask}
                  onDelete={onDeleteTask}
                  theme={theme}
                />
              ))}
            </ScrollArea>
          </TabsContent>
        </Tabs>
      </CardContent>
      <TaskDialog 
        open={showAddDialog}
        onOpenChange={setShowAddDialog}
        onSave={handleTaskSave}
        task={taskToEdit}
      />
    </Card>
  );
};

export default TaskDashboard;