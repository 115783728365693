import { ref, set, get, update } from 'firebase/database';
import { database } from '../config/firebase';

export const dbService = {
  saveUserData: async (userId, data) => {
    try {
      const userRef = ref(database, `users/${userId}`);
      await set(userRef, data);
    } catch (error) {
      console.error('Error saving user data:', error);
      throw error;
    }
  },

  getUserData: async (userId) => {
    try {
      const userRef = ref(database, `users/${userId}`);
      const snapshot = await get(userRef);
      return snapshot.val();
    } catch (error) {
      console.error('Error getting user data:', error);
      throw error;
    }
  },

  saveTasks: async (userId, tasks) => {
    try {
      const tasksRef = ref(database, `users/${userId}/tasks`);
      await set(tasksRef, tasks);
    } catch (error) {
      console.error('Error saving tasks:', error);
      throw error;
    }
  },

  updateUserSettings: async (userId, settings) => {
    try {
      const settingsRef = ref(database, `users/${userId}/settings`);
      await update(settingsRef, settings);
    } catch (error) {
      console.error('Error updating settings:', error);
      throw error;
    }
  }
};