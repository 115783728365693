import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import { Clock } from 'lucide-react';
import { formatTime } from '../utils/timeUtils';

const ProgressCard = ({ tasks, totalTime, weeklyGoal, theme }) => {
  const weeklyProgress = (totalTime / weeklyGoal) * 100;
  const completedTasks = tasks.filter(task => task.isCompleted).length;
  const totalTasks = tasks.length;
  const completionRate = totalTasks ? (completedTasks / totalTasks) * 100 : 0;
  const activeTasks = tasks.filter(task => !task.isCompleted).length;
  const inProgressTasks = tasks.filter(task => task.isRunning).length;
  const averageTimePerTask = totalTasks ? Math.floor(totalTime / totalTasks) : 0;

  return (
    <Card className="border-none shadow-lg">
      <CardHeader>
        <CardTitle className="flex items-center text-xl font-bold">
          <Clock className="mr-2 h-5 w-5" /> Weekly Progress
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div>
            <div className="flex justify-between mb-1">
              <span>Time Spent:</span>
              <span>{formatTime(totalTime)}</span>
            </div>
            <Progress value={weeklyProgress} className="h-2" />
            <div className="text-sm opacity-70 mt-1">
              {weeklyProgress.toFixed(1)}% of weekly goal ({formatTime(weeklyGoal)})
            </div>
          </div>

          <div className="mt-4">
            <div className="flex justify-between mb-1">
              <span>Tasks Completed:</span>
              <span>{completedTasks}/{totalTasks}</span>
            </div>
            <Progress value={completionRate} className="h-2" />
            <div className="text-sm opacity-70 mt-1">
              {completionRate.toFixed(1)}% completion rate
            </div>
          </div>

          <div className="mt-4 p-4 bg-opacity-10 bg-white rounded-lg">
            <h3 className="font-semibold mb-2">Quick Stats</h3>
            <div className="grid grid-cols-2 gap-2 text-sm">
              <div>Active Tasks:</div>
              <div className="text-right">{activeTasks}</div>
              <div>In Progress:</div>
              <div className="text-right">{inProgressTasks}</div>
              <div>Average Time/Task:</div>
              <div className="text-right">{formatTime(averageTimePerTask)}</div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProgressCard;