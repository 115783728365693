// src/utils/memeService.js
const MEMES = [
    {
      url: "https://images7.memedroid.com/images/UPLOADED353/672fb14ceef1d.webp",
      title: ""
    },
    {
      url: "https://images7.memedroid.com/images/UPLOADED866/6730126958056.webp",
      title: ""
    },
    {
      url: "https://i.chzbgr.com/full/9034838528/h7C616957/person-and-all-my-friends-wake-up-after-weekend-thestupidamerican-survived-but-dead",
      title: ""
    },
    {
      url: "https://images7.memedroid.com/images/UPLOADED262/672f74b139f79.webp",
      title: ""
    },
    {
      url: "https://images3.memedroid.com/images/UPLOADED393/6730372102c39.webp",
      title: ""
    },
    // Add more memes as needed
  ];
  
  export const memeService = {
    getMeme: () => {
      const randomIndex = Math.floor(Math.random() * MEMES.length);
      return MEMES[randomIndex];
    },
  
    // Get the next meme in sequence
    getNextMeme: (currentMemeUrl) => {
      const currentIndex = MEMES.findIndex(meme => meme.url === currentMemeUrl);
      const nextIndex = (currentIndex + 1) % MEMES.length;
      return MEMES[nextIndex];
    }
  };