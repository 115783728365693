import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Button } from '@/components/ui/button';
import { AlertCircle } from 'lucide-react';

const Login = () => {
  const { signIn } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignIn = async () => {
    try {
      setError('');
      setLoading(true);
      await signIn();
    } catch (error) {
      console.error('Login error:', error);
      setError(
        error.code === 'auth/popup-closed-by-user'
          ? 'Sign in was cancelled. Please try again.'
          : 'Failed to sign in. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="text-center space-y-8 p-8 rounded-lg max-w-md w-full">
        <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">
          TabTask
        </h1>
        <p className="text-gray-400">
          Transform your browser's new tab into a powerful task management dashboard.
        </p>
        
        {error && (
          <div className="bg-red-500 bg-opacity-10 text-red-500 p-4 rounded-lg flex items-center gap-2">
            <AlertCircle className="h-5 w-4" />
            <p>{error}</p>
          </div>
        )}

        <Button 
          onClick={handleSignIn}
          className="w-full bg-blue-600 hover:bg-blue-700"
          disabled={loading}
        >
          {loading ? (
            <div className="flex items-center justify-center gap-2">
              <div className="w-4 h-4 border-2 border-t-transparent border-white rounded-full animate-spin" />
              <span>Signing in...</span>
            </div>
          ) : (
            'Sign in with Google'
          )}
        </Button>
      </div>
    </div>
  );
};

export default Login;