import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Share, RefreshCw } from 'lucide-react';
import { storage } from '../utils/storage';
import { memeService } from '../utils/memeService';

const MEME_REFRESH_INTERVAL = 120000; // 2 minutes in milliseconds

const MemeCard = ({ theme }) => {
  const [memeData, setMemeData] = useState(null);
  const [nextRefresh, setNextRefresh] = useState(null);

  useEffect(() => {
    const loadMeme = async () => {
      const stored = await storage.get('memeData');
      const now = Date.now();

      if (stored && stored.nextRefresh > now) {
        setMemeData(stored);
        setNextRefresh(stored.nextRefresh);
      } else {
        const newMeme = memeService.getMeme();
        const nextRefreshTime = now + MEME_REFRESH_INTERVAL;
        
        const newMemeData = {
          ...newMeme,
          nextRefresh: nextRefreshTime
        };

        await storage.set('memeData', newMemeData);
        setMemeData(newMemeData);
        setNextRefresh(nextRefreshTime);
      }
    };

    loadMeme();
  }, []);

  useEffect(() => {
    const timer = setInterval(async () => {
      const now = Date.now();
      if (nextRefresh && now >= nextRefresh) {
        const newMeme = memeService.getNextMeme(memeData?.url);
        const nextRefreshTime = now + MEME_REFRESH_INTERVAL;
        
        const newMemeData = {
          ...newMeme,
          nextRefresh: nextRefreshTime
        };

        await storage.set('memeData', newMemeData);
        setMemeData(newMemeData);
        setNextRefresh(nextRefreshTime);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [memeData, nextRefresh]);

  const handleRefresh = async () => {
    const newMeme = memeService.getMeme();
    const now = Date.now();
    const nextRefreshTime = now + MEME_REFRESH_INTERVAL;
    
    const newMemeData = {
      ...newMeme,
      nextRefresh: nextRefreshTime
    };

    await storage.set('memeData', newMemeData);
    setMemeData(newMemeData);
    setNextRefresh(nextRefreshTime);
  };

  const getTimeUntilRefresh = () => {
    if (!nextRefresh) return '00:00';
    const now = Date.now();
    const diff = Math.max(0, nextRefresh - now);
    const minutes = Math.floor(diff / 60000);
    const seconds = Math.floor((diff % 60000) / 1000);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  if (!memeData) return null;

  return (
    <Card className="border-none shadow-lg">
      <CardHeader>
        <CardTitle className="text-xl font-bold flex justify-between items-center">
          <span>Meme of the Moment</span>
          <Button variant="ghost" size="sm" onClick={handleRefresh}>
            <RefreshCw className="h-4 w-4" />
          </Button>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="relative">
          <img
            src={memeData.url}
            alt={memeData.title}
            className="w-full h-auto rounded-lg mb-4"
          />
          <div className="absolute top-2 right-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded text-sm">
            {memeData.title}
          </div>
        </div>
        <div className="flex justify-between items-center">
          <Button variant="outline" size="sm" onClick={() => {}}>
            <Share className="h-4 w-4 mr-2" /> Share
          </Button>
          <p className="text-sm opacity-70">Next meme in: {getTimeUntilRefresh()}</p>
        </div>
      </CardContent>
    </Card>
  );
};

export default MemeCard;