import React, { useState, useEffect } from 'react';
import { Mail } from 'lucide-react';

const QUOTES = [
  {
    text: "The only way to do great work is to love what you do.",
    author: "Steve Jobs"
  },
  {
    text: "Success is not final, failure is not fatal: it is the courage to continue that counts.",
    author: "Winston Churchill"
  },
  {
    text: "Every strike brings me closer to the next home run.",
    author: "Babe Ruth"
  },
  {
    text: "The fastest way to change yourself is to hang out with people who are already the way you want to be.",
    author: "Reid Hoffman"
  },
  {
    text: "It's not about ideas. It's about making ideas happen.",
    author: "Scott Belsky"
  },
  {
    text: "Fail fast, fail often, but always fail forward.",
    author: "John C. Maxwell"
  },
  {
    text: "The best way to predict the future is to create it.",
    author: "Peter Drucker"
  }
];

const Footer = () => {
  const [quote, setQuote] = useState(QUOTES[0]);

  useEffect(() => {
    const randomQuote = () => {
      const randomIndex = Math.floor(Math.random() * QUOTES.length);
      setQuote(QUOTES[randomIndex]);
    };

    randomQuote();
    const interval = setInterval(randomQuote, 60000); // Change quote every minute

    return () => clearInterval(interval);
  }, []);

  return (
    <footer className="mt-16 pb-8 text-center">
      <div className="max-w-4xl mx-auto px-4">
        <div className="mb-8 py-6 px-4 rounded-lg bg-opacity-5 bg-white">
          <p className="text-lg italic mb-2">{quote.text}</p>
          <p className="text-sm opacity-70">― {quote.author}</p>
        </div>
        
        <div className="text-sm opacity-70">
          <p className="mb-2">Made with ❤️ by AI</p>
          <p className="flex items-center justify-center gap-2">
            <Mail className="h-4 w-4" />
            <a 
              href="mailto:letsstartagain02@gmail.com" 
              className="hover:text-blue-400 transition-colors"
            >
              Feature requests: letsstartagain02@gmail.com
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;