import { auth, database } from '../config/firebase';
import { setPersistence, browserLocalPersistence } from 'firebase/auth';
import { ref, onValue } from 'firebase/database';

export const initializeFirebase = async () => {
  try {
    // Set auth persistence
    await setPersistence(auth, browserLocalPersistence);
    
    // Initialize database connection
    const dbRef = ref(database, '.info/connected');
    
    return new Promise((resolve) => {
      const unsubscribe = onValue(dbRef, (snap) => {
        unsubscribe(); // Unsubscribe after first value
        if (snap.val() === true) {
          console.log('Connected to Firebase');
          resolve(true);
        } else {
          console.log('Not connected to Firebase');
          resolve(false);
        }
      });

      // Set a timeout for the connection check
      setTimeout(() => {
        unsubscribe();
        resolve(true); // Resolve anyway after timeout
      }, 5000);
    });

  } catch (error) {
    console.error('Firebase initialization error:', error);
    return false;
  }
};