// src/components/ThemeSwitcher.jsx
import React from 'react';
import { Settings } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

const ThemeSwitcher = ({ currentTheme, onThemeChange }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <Settings className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => onThemeChange('dark')}>
          Dark Theme
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onThemeChange('light')}>
          Light Theme
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onThemeChange('blue')}>
          Blue Theme
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onThemeChange('green')}>
          Green Theme
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ThemeSwitcher;