import React, { useState } from 'react';
import { Play, Pause, Check, Trash2, Edit } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Progress } from '@/components/ui/progress';
import { Badge } from '@/components/ui/badge';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { formatTime } from '../utils/timeUtils';
import TaskDialog from './TaskDialog';

const TaskItem = ({ task, onUpdate, onDelete, theme }) => {
  const [showEditDialog, setShowEditDialog] = useState(false);

  const toggleTimer = () => {
    onUpdate({
      ...task,
      isRunning: !task.isRunning,
      lastUpdated: Date.now() // Add timestamp for accurate timer tracking
    });
  };

  const toggleComplete = () => {
    onUpdate({
      ...task,
      isCompleted: !task.isCompleted,
      isRunning: false
    });
  };

  const handleDelete = () => {
    onDelete(task.id);
  };

  const progress = (task.time / task.estimatedTime) * 100;

  return (
    <div className={`flex items-center space-x-2 bg-opacity-10 bg-white dark:bg-opacity-10 dark:bg-black p-4 rounded-lg transition-all hover:bg-opacity-20 mb-4`}>
      <Checkbox 
        checked={task.isCompleted}
        onCheckedChange={toggleComplete}
        className="border-current"
      />
      
      <div className="flex-grow">
        <div className={`text-lg font-medium ${task.isCompleted ? 'line-through opacity-50' : ''}`}>
          {task.title}
        </div>
        <div className="text-sm opacity-70">{task.description}</div>
        <div className="text-xs opacity-50 mt-1">
          Due: {new Date(task.dueDate).toLocaleDateString()} | Category: {task.category}
        </div>
        <Progress value={progress} className="h-1 mt-2" />
      </div>

      <Badge variant={task.isRunning ? "default" : "secondary"} className="ml-2">
        {formatTime(task.time)} / {formatTime(task.estimatedTime)}
      </Badge>

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="icon" onClick={toggleTimer}>
              {task.isRunning ? <Pause className="h-4 w-4" /> : <Play className="h-4 w-4" />}
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>{task.isRunning ? 'Pause' : 'Start'} timer</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="icon" onClick={() => setShowEditDialog(true)}>
              <Edit className="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Edit task</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="icon" onClick={handleDelete}>
              <Trash2 className="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Delete task</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <TaskDialog
        open={showEditDialog}
        onOpenChange={setShowEditDialog}
        onSave={(updatedTask) => {
          onUpdate({ ...updatedTask, id: task.id, time: task.time });
          setShowEditDialog(false);
        }}
        task={task}
      />
    </div>
  );
};

export default TaskItem;