import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyCF9vZ5cjFcu5tfjaeGRuwGoVcPkqBe_x0",
  authDomain: "tabtask-ffa33.firebaseapp.com",
  projectId: "tabtask-ffa33",
  storageBucket: "tabtask-ffa33.appspot.com",
  messagingSenderId: "873050992384",
  appId: "1:873050992384:web:ca7fd5583b76a02aa5bfa2",
  databaseURL: "https://tabtask-ffa33-default-rtdb.firebaseio.com"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Auth with persistence
const auth = getAuth(app);

// Initialize Realtime Database
const database = getDatabase(app);

// Enable emulator in development if needed
if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectDatabaseEmulator(database, 'localhost', 9000);
}

export { auth, database };