import { 
    signInWithPopup, 
    GoogleAuthProvider, 
    signInWithCredential,
    signOut as firebaseSignOut,
    onAuthStateChanged
  } from 'firebase/auth';
  import { auth } from '../config/firebase';
  
  const isExtension = window.chrome && chrome.runtime && chrome.runtime.id;
  
  const handleExtensionAuth = () => {
    return new Promise((resolve, reject) => {
      chrome.identity.getAuthToken({ 
        interactive: true,
        scopes: [
          'https://www.googleapis.com/auth/userinfo.email',
          'https://www.googleapis.com/auth/userinfo.profile'
        ]
      }, async function(token) {
        if (chrome.runtime.lastError) {
          reject(new Error(chrome.runtime.lastError.message));
          return;
        }
  
        try {
          // Get user info
          const response = await fetch(
            'https://www.googleapis.com/oauth2/v3/userinfo',
            {
              headers: { Authorization: `Bearer ${token}` }
            }
          );
          
          if (!response.ok) {
            throw new Error('Failed to get user info');
          }
  
          const userInfo = await response.json();
          
          // Create Firebase credential
          const credential = GoogleAuthProvider.credential(null, token);
          const result = await signInWithCredential(auth, credential);
          resolve(result.user);
        } catch (error) {
          chrome.identity.removeCachedAuthToken({ token }, () => {});
          reject(error);
        }
      });
    });
  };
  
  const handleWebAuth = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    return signInWithPopup(auth, provider);
  };
  
  export const authService = {
    signInWithGoogle: async () => {
      try {
        if (isExtension) {
          return await handleExtensionAuth();
        } else {
          return await handleWebAuth();
        }
      } catch (error) {
        console.error('Error signing in with Google:', error);
        throw error;
      }
    },
  
    signOut: async () => {
      try {
        if (isExtension) {
          await new Promise((resolve) => {
            chrome.identity.clearAllCachedAuthTokens(resolve);
          });
        }
        await firebaseSignOut(auth);
      } catch (error) {
        console.error('Error signing out:', error);
        throw error;
      }
    },
  
    getCurrentUser: () => {
      return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(
          auth,
          (user) => {
            unsubscribe();
            resolve(user);
          },
          reject
        );
      });
    },
  
    onAuthStateChange: (callback) => {
      return onAuthStateChanged(auth, callback);
    }
  };