import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

const PRESET_WEEKLY_GOALS = [
  { label: '20 hours/week', value: 72000 },  // 20 hours in seconds
  { label: '30 hours/week', value: 108000 }, // 30 hours in seconds
  { label: '40 hours/week', value: 144000 }, // 40 hours in seconds
  { label: 'Custom', value: 'custom' }
];

const UserSetup = ({ open, onComplete, isInitialSetup = true, initialData = null }) => {
  const [userName, setUserName] = useState('');
  const [weeklyGoal, setWeeklyGoal] = useState(144000);
  const [customHours, setCustomHours] = useState(40);
  const [showCustomInput, setShowCustomInput] = useState(false);

  useEffect(() => {
    if (initialData) {
      setUserName(initialData.userName || '');
      setWeeklyGoal(initialData.weeklyGoal || 144000);
      setCustomHours(Math.floor((initialData.weeklyGoal || 144000) / 3600));
    }
  }, [initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const finalGoal = showCustomInput ? customHours * 3600 : weeklyGoal;
    onComplete({
      userName: userName || initialData?.userName,
      weeklyGoal: finalGoal
    });
  };

  const handleWeeklyGoalChange = (value) => {
    if (value === 'custom') {
      setShowCustomInput(true);
    } else {
      setShowCustomInput(false);
      setWeeklyGoal(Number(value));
    }
  };

  return (
    <Dialog open={open}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {isInitialSetup ? 'Welcome to TabTask' : 'Update Settings'}
          </DialogTitle>
          <DialogDescription>
            {isInitialSetup 
              ? "Let's get you set up with TabTask. Please enter your name and weekly goal."
              : "Update your TabTask settings."
            }
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-6 pt-4">
          {isInitialSetup && (
            <div className="space-y-2">
              <Label htmlFor="userName">Your Name</Label>
              <Input
                id="userName"
                placeholder="Enter your name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required={isInitialSetup}
              />
            </div>
          )}
          
          <div className="space-y-2">
            <Label htmlFor="weeklyGoal">Weekly Goal</Label>
            <Select
              onValueChange={handleWeeklyGoalChange}
              defaultValue={weeklyGoal.toString()}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select your weekly goal" />
              </SelectTrigger>
              <SelectContent>
                {PRESET_WEEKLY_GOALS.map((goal) => (
                  <SelectItem key={goal.value} value={goal.value.toString()}>
                    {goal.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {showCustomInput && (
            <div className="space-y-2">
              <Label htmlFor="customHours">Custom Hours per Week</Label>
              <Input
                id="customHours"
                type="number"
                min="1"
                max="168"
                value={customHours}
                onChange={(e) => setCustomHours(Number(e.target.value))}
              />
            </div>
          )}

          <DialogFooter>
            <Button type="submit">
              {isInitialSetup ? 'Get Started' : 'Save Changes'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UserSetup;