import { storage } from './storage';

export const taskService = {
  // Update running tasks' times
  updateRunningTasks: async (tasks) => {
    const now = Date.now();
    const updatedTasks = tasks.map(task => {
      if (task.isRunning) {
        const lastUpdated = task.lastUpdated || now;
        const timeDiff = Math.floor((now - lastUpdated) / 1000);
        return {
          ...task,
          time: task.time + timeDiff,
          lastUpdated: now
        };
      }
      return task;
    });

    await storage.set('tasks', updatedTasks);
    return updatedTasks;
  },

  // Save tasks to storage
  saveTasks: async (tasks) => {
    await storage.set('tasks', tasks);
    return tasks;
  },

  // Load tasks from storage
  loadTasks: async () => {
    const tasks = await storage.get('tasks');
    return tasks || [];
  }
};