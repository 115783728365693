// src/utils/themeUtils.js
export const themes = {
    dark: {
      background: "bg-gray-900",
      text: "text-gray-100",
      card: "bg-gray-800",
    },
    light: {
      background: "bg-gray-100",
      text: "text-gray-900",
      card: "bg-white",
    },
    blue: {
      background: "bg-blue-900",
      text: "text-blue-100",
      card: "bg-blue-800",
    },
    green: {
      background: "bg-green-900",
      text: "text-green-100",
      card: "bg-green-800",
    },
  };